<div *ngIf="maestrias.length == 0 && doctorados.length == 0">
  <h3>Sin programas en convocatoria</h3>
  <p>
    En este momento no tenemos convocatorias activas, pero te invitamos a explorar nuestra oferta de 
    <a class="underline text-espg-cobalt-blue" routerLink="/maestrias">maestrías</a> y <a class="underline text-espg-cobalt-blue" routerLink="/doctorados">doctorados</a>
  </p>
  <p class="font-bold text-espg-cobalt-blue">PRÓXIMA ADMISIÓN: ENERO 2025</p>
</div>

<div class="template--lista-programa-convocados" *ngIf="maestrias.length > 0">
    <h3>Maestrías</h3>
    <div class="card-container">
      <ng-container  *ngFor="let programa of maestrias" >
        <a [routerLink]="['/maestrias',programa.slug]" >
          <nz-card class="card"   [nzCover]="coverTemplate" *ngIf="programa.imagen" >
              <nz-card-meta [nzTitle]="recotrarDenominacion('maestria',programa.nombre)" >
              </nz-card-meta>   
                          
            <ng-template #coverTemplate>
              <img alt="example" [src]="programa.imagen" class="obj" />
            </ng-template> 
          </nz-card>

          <nz-card class="card" *ngIf="!programa.imagen"   >
              <nz-card-meta [nzTitle]="recotrarDenominacion('maestria',programa.nombre)" >
              </nz-card-meta>   
          </nz-card>
        </a>
      </ng-container>
    </div>
</div>


<div class="template--lista-programa-convocados mt-6" *ngIf="doctorados.length > 0">
    <h3>Doctorados</h3>
    <div class="card-container">
      <ng-container  *ngFor="let programa of doctorados">
        <a [routerLink]="['/doctorados',programa.slug]" >
          <nz-card class="card"   [nzCover]="coverTemplate" *ngIf="programa.imagen" >
            <nz-card-meta [nzTitle]="recotrarDenominacion('doctorado',programa.nombre)" >
            </nz-card-meta>   
                        
          <ng-template #coverTemplate>
            <img alt="example" [src]="programa.imagen" class="obj" />
          </ng-template> 
        </nz-card>

        <nz-card class="card" *ngIf="!programa.imagen"  >
            <nz-card-meta [nzTitle]="recotrarDenominacion('doctorado',programa.nombre)" >
            </nz-card-meta>   
        </nz-card>
        </a>
      </ng-container>    
    </div>
</div>
  